import React, { useState } from "react";
import { LeadMagnet } from "../../Context";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

interface Props {
    leadMagnet : LeadMagnet,
    sourceURL : string
}

const EmbedCode = ({leadMagnet, sourceURL}:Props) : React.ReactElement => {
    const [copied, setCopied] = useState<boolean>(false);
    let script : string = `
                <div id="LeadHarbor-${(leadMagnet)?leadMagnet._id:""}">
                    <div class="card-skeleton">
                            <div class="skeleton-image"></div>
                        </div>
                        <style>.card-skeleton {
                            width: 100%;
                            height: calc(40vh);
                            background: #f0f0f0;
                            border-radius: 8px;
                            animation: shimmer 1.5s infinite;
                        }
                        .skeleton-image {
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                            background-size: 200% 100%;
                            animation: shimmer 1.5s infinite;
                        }
                        </style>
                    <script src="${sourceURL}/leadHarbor.js"></script>
                </div>`;

    return(
        <div className="m-5">
            {
                (leadMagnet)
                ?<div className="p-5 bg-brandLightGray rounded-xl shadow-md">
                    <div className="flex flex-row justify-start items-center mb-5">
                        <p className="text-xl w-1/2">Embed code for your website</p>
                        <div className="flex justify-end w-1/2">
                            <button onClick={()=>{
                                navigator.clipboard.writeText(script); 
                                setCopied(true); 
                                setTimeout(()=>{setCopied(false)}, 4000)}} 
                            className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple">
                                <FaRegCopy className="size-5"/>Copy to clipboard
                            </button>
                        </div>
                    </div>
                    <textarea rows={23} className="w-full h-50 text-xs textarea textarea-bordered bg-white"
                    value={script}/>
                    {(copied)
                    ?<div role="alert" className="alert bg-green text-white">
                        <p className="flex justify-start items-center"><FaCheck className="size-5 mr-2"/>Script has been copied to your clipboard</p>
                    </div>
                    :null
                    }
                </div>
                :<div className="skeleton h-96 w-full"></div>
            }
        </div>
    );
};

export default EmbedCode;