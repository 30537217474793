import React, { useContext, useState } from "react";
import { Context } from "../../components/Context";
import { FiLogOut } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { IoRocketSharp } from "react-icons/io5";
import FunnelAnalytics from "../../components/user/sequenceInfo/FunnelAnalytics";
import GeneratedLeads from "../../components/user/sequenceInfo/GeneratedLeads";
import FunnelSteps from "../../components/user/sequenceInfo/FunnelSteps";
import DeleteFunnel from "../../components/user/sequenceInfo/DeleteFunnel";

const MagnetInfo = () : React.ReactElement => {
    const { loadDone, selectedMagnet, workspace, user, userLogOut, magnetLeads , changeFormName, userToken, deleteForm, navigate, setSteps} = useContext(Context);
    const [nameEditor, setNameEditor] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>();
    const [activeViewElement, setActiveViewElement] = useState<React.ReactElement>(<FunnelAnalytics />);
    const [activeViewIdx, setActiveViewIdx] = useState<number>(1);
    
    return(
        <div>
            <div className="w-full bg-brandLightGray p-5 flex shadow-sm max-lg:hidden" style={{boxShadow :"1px 0px 4px 0.5px gray"}}>
            <div className="flex-1">
                <div>
                    <div className="flex flex-row justify-start items-center gap-2">
                        <p className="text-2xl">{`Lead Magnets | `}</p>
                        {
                            (nameEditor)
                            ?<><input type="text" value={newName} onChange={(e)=>{setNewName(e.target.value as string)}} className="w-1/4 ml-2 input input-bordered"/>
                                <button onClick={()=>{setNameEditor(false);}} 
                                className="btn border-red text-red hover:bg-red hover:text-brandLightGray font-light rounded-2xl">
                                    Cancel
                                </button>
                                <button onClick={()=>{changeFormName(selectedMagnet._id, userToken, newName); setNameEditor(false);}} 
                                className="btn bg-brandPurple text-brandLightGray hover:bg-brandLightPurple font-light rounded-2xl">
                                    Save
                                </button>
                             </>
                            :<p className="text-2xl flex flex-row justify-start items-center">
                                {(selectedMagnet)? selectedMagnet.form_name :<span className="skeleton w-36 h-14"></span>} 
                                <span className="tooltip tooltip-right" data-tip="Edit your lead magnets' name" onClick={()=>{setNameEditor(true); setNewName(selectedMagnet.form_name);}}>
                                    <MdEdit className="size-5 ml-2"/>
                                </span>
                             </p>
                        }
                    </div>
                    <p></p>
                </div>
            </div>
            <div className="dropdown dropdown-end">
                <div className="tooltip tooltip-left" data-tip="Open user settings">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar placeholder">
                        <div className="w-52 rounded-full bg-brandPurple text-brandLightGray text-xl font-light">
                            <span>{user.first_name[0]}{user.last_name[0]}</span>
                        </div>
                    </div>
                </div>
                <ul tabIndex={0} className="dropdown-content rounded-box z-[1] mt-2 p-3 shadow bg-neutral-50 text-black">
                    <li className="p-2 rounded-md flex flex-col text-xs mt-2">
                        <span className="text-sm">{`${user.first_name} ${user.last_name}`}</span>
                        <span className="text-brandPurple">{user.email}</span>
                    </li>
                    <li className="hover:bg-brandLightGray p-2 rounded-md cursor-pointer flex flex-row mt-2" onClick={userLogOut}>
                        <FiLogOut className="size-5 text-black"/>Log out
                    </li>
                </ul>
            </div>
           
            </div>
            <div className="w-full relative p-5">
                <Link to="/sequences" onClick={()=>{sessionStorage.removeItem("form");}}>
                    <p className="flex flex-row justify-start items-center gap-2 text-brandPurple"><MdArrowBackIosNew className="size-5"/> Back to all sequences</p>
                </Link>
                <div className="lg:absolute lg:top-5 lg:right-3 flex flex-row max-lg:mt-5 gap-2">
                    <div>
                        <button onClick={()=>{navigate("/sequences/preview")}} 
                        className="btn flex flex-row justify-start items-center gap-2 bg-brandPurple text-brandLightGray hover:bg-brandLightPurple font-light rounded-xl">
                            <IoRocketSharp className="size-5 max-md:hidden"/> Preview & Embed
                        </button>
                    </div>
                    <div>
                        <button onClick={()=>{
                            sessionStorage.setItem("steps", JSON.stringify(selectedMagnet.steps));
                            setSteps(selectedMagnet.steps); 
                            navigate("/sequences/edit");}}
                            className="btn flex flex-row justify-start items-center gap-2 bg-brandLightGray text-black hover:bg-brandLightPurple hover:text-brandLightGray font-light rounded-xl">
                            <MdEdit className="size-5 max-md:hidden"/> Edit funnel
                        </button>
                    </div>
                    <DeleteFunnel leadMagnet={selectedMagnet} workspace={workspace} deleteForm={deleteForm} userToken={userToken}/>
                </div>
            </div>
            <div className="join p-5 lg:w-1/2">
                <button className={`btn join-item w-1/3 rounded-2xl font-light hover:bg-brandLightPurple hover:text-brandLightGray border-brandPurple
                ${(activeViewIdx === 1)?"bg-brandPurple text-brandLightGray":""}`}
                onClick={()=>{setActiveViewElement(<FunnelAnalytics/>); setActiveViewIdx(1);}}>
                    1. Funnel Analytics
                </button>
                <button className={`btn join-item w-1/3 rounded-2xl font-light hover:bg-brandLightPurple hover:text-brandLightGray border-brandLightPurple 
                ${(activeViewIdx === 2)?"bg-brandPurple text-brandLightGray":""} ${(loadDone && magnetLeads && magnetLeads.length > 0)?"":"hidden"}`}
                onClick={()=>{setActiveViewElement(<GeneratedLeads/>); setActiveViewIdx(2)}}>
                    2. Generated Leads
                </button>
                <button className={`btn join-item w-1/3 rounded-2xl font-light  hover:bg-brandLightPurple hover:text-brandLightGray border-brandLightPurple
                ${(activeViewIdx === 3)?"bg-brandPurple text-brandLightGray":""}`}
                onClick={()=>{setActiveViewElement(<FunnelSteps/>); setActiveViewIdx(3);}}>
                    3. Funnel Steps
                </button>
            </div>
            {activeViewElement}
        </div>
    );
};

export default MagnetInfo;