import React, { useState } from "react";
import { Step, ThankYouInterface } from "../../Context";
import { FaPen } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { GrLinkNext } from "react-icons/gr";
import EndContent from "./EndContent";
import EndStyle from "./EndStyle";

interface Props {
    step : Step,
    steps : Step[],
    setSteps : Function,
    idx : number,
    setEndScreenAdded : Function
}

const EndStep = ({step, steps, setSteps, idx, setEndScreenAdded}:Props) : React.ReactElement => {
    const [editForm, setEditForm] = useState<boolean>(false);
    const [thankYouPage, setThankYouPage] = useState<ThankYouInterface>({
        h1 : (step.thankYouPage) ? step.thankYouPage.h1 : "",
        description : (step.thankYouPage) ? step.thankYouPage.description : "",
        ctaText : (step.thankYouPage) ? step.thankYouPage.ctaText : "",
        ctaUrl : (step.thankYouPage) ? step.thankYouPage.ctaUrl : "",
        textScreenStyle : (step.thankYouPage && step.thankYouPage.textScreenStyle) ? step.thankYouPage.textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : (step.thankYouPage && step.thankYouPage.h1Style) ? step.thankYouPage.h1Style : {fontSize : "24", color : ""},
        pStyle : (step.thankYouPage && step.thankYouPage.pStyle) ? step.thankYouPage.pStyle : {fontSize : "18", color : ""},
        ctaStyle : (step.thankYouPage && step.thankYouPage.ctaStyle) ? step.thankYouPage.ctaStyle :  {fontSize : "18", color : "#ffffff", backgroundColor : "#000000", padding : "10", borderRadius : "10"}
    });
    const [editScreen, setEditScreen] = useState<string>("content");
    const [editContent, setEditContent] = useState<React.ReactElement>(<EndContent idx={idx} thankYouPage={thankYouPage} setThankYouPage={setThankYouPage}/>);

    const saveToStorage = () => {
        sessionStorage.removeItem(`end${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps));
    }


    const removeStep = () => {
        setEndScreenAdded(false);
        sessionStorage.removeItem(`end${idx}`)
        sessionStorage.setItem(`steps`, JSON.stringify(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx])));
        setSteps(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx]));
    }


    return(
        <div className="bg-brandLightGray p-3 2xl:w-1/3 xl:w-1/2 lg:w-1/2 rounded-xl shadow-md" key={idx}>
            <div className="flex flex-row justify-start items-center">
                <div className="w-1/2">
                    <p className="flex flex-row justify-start items-center text-lg"><GrLinkNext className="size-6 mr-1 text-brandPurple"/><b>Step {idx + 1}: </b> End Screen</p>
                </div>
                <div className="w-1/2 flex justify-end gap-2">
                    <button className="btn rounded-full border-red boder-[1px] text-black bg-white hover:bg-red hover:text-white font-light"
                    onClick={removeStep}>
                        <MdDeleteForever className="size-4"/>Remove</button>
                    <button onClick={()=>{setEditForm(true);}} 
                    className="btn rounded-full font-light border-[1px] text-sm border-brandPurple bg-white hover:bg-brandLightPurple hover:text-white">
                        <FaPen className="size-4"/> Edit Step
                    </button>
                </div>
            </div>
            <div className="p-2">
                <div className={`text-${thankYouPage.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${thankYouPage.textScreenStyle.padding}px`, 
                            backgroundColor : `${thankYouPage.textScreenStyle.backgroundColor}`}}>
                    {(thankYouPage.h1)?
                    <p style={{fontSize : `${thankYouPage.h1Style.fontSize}px`, color : `${thankYouPage.h1Style.color}`}}>
                        {thankYouPage.h1}
                    </p>
                    :null}
                    {(thankYouPage.description)?
                    <p style={{fontSize : `${thankYouPage.pStyle.fontSize}px`, color : `${thankYouPage.pStyle.color}`}}>
                        {thankYouPage.description}
                    </p>
                    :null}
                    <button
                    style={{fontSize : `${thankYouPage.ctaStyle.fontSize}px`, padding :  `${thankYouPage.ctaStyle.padding}px`, borderRadius : `${thankYouPage.ctaStyle.borderRadius}px`,
                    color :  `${thankYouPage.ctaStyle.color}`, backgroundColor :  `${thankYouPage.ctaStyle.backgroundColor}`}}>
                        {(thankYouPage.ctaText)?thankYouPage.ctaText:"Continue"}
                    </button>
                </div>
            </div>
            <input type="checkbox" checked={editForm} id="videoEditor" className="modal-toggle" readOnly/>
            <div className="modal" role="dialog">
                <div className="modal-box w-[80%] max-w-none">
                    <div className="flex flex-row justify-start items-center pb-5 border-b-[1px]">
                        <div className="w-1/3">
                            <p className="text-lg"><b>Editing:</b> Step {idx + 1}: End Screen</p>
                        </div>
                        <div className="w-1/3 join flex justify-center">
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "content")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{; setEditScreen("content"); setEditContent(<EndContent idx={idx} thankYouPage={thankYouPage} setThankYouPage={setThankYouPage}/>)}}>
                                Content
                            </button>
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "style")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{setEditScreen("style"); setEditContent(<EndStyle idx={idx} thankYouPage={thankYouPage} setThankYouPage={setThankYouPage}/>)}}>Style</button>
                        </div>
                        <div className="w-1/3 flex justify-end gap-2">
                            <button className="btn rounded-full border-2 border-red text-red font-light hover:bg-red hover:text-white bg-white"
                            onClick={()=>{setEditForm(false);}}>Cancel</button>
                            <button className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple" 
                            onClick={()=>{setEditForm(false); step.thankYouPage = thankYouPage; saveToStorage();}}>Save & Close</button>
                        </div>
                    </div>
                    {editContent}
                </div>
            </div>
        </div>
    );
};

export default EndStep